import {createRouter, createWebHistory} from "vue-router";

/* const redirectTo = () => {
 
   return '/home';
 } */

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    components:{
      default:()=>  import('@/views/NotFoundView.vue'),
      
    } 
  },
  {
    path: '/notHavePermission',
    name: 'NotHavePermission',
    components:{
      default:()=>  import('@/views/NotHaveAccesView.vue'),
      
    } 
  },
  {
    path: '/login',
    name: 'Loggin',
    components:{
      default:()=>  import('@/views/LogginViewVue.vue'),
      
    } 
  },{
    path: '/forgotpassword',
    name: 'forgotpassword',
    components:{
      default:()=>  import('@/views/ForgotPasswordView.vue'),

    }
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    components:{
      default:()=>  import('@/views/ResetPasswordView.vue'),

    }
  },

  {
    path: '/select-tasks',
    name: 'select-tasks',
    components:{
      default:()=>  import('@/views/HomeSelectWidgetTasks.vue'),

    },
    meta: { authorize: ['ROLE_ADMIN','ROLE_USER'] } ,
  },
  {
    path: '/options',
    name: 'options',
    components:{
      default:()=>  import('@/views/HomeSelectWidgetOptions.vue'),

    },
    meta: { authorize: ['ROLE_ADMIN','ROLE_USER'] } ,
  },
  {
    path: '/move',
    name: 'select-move',
    components:{
      default:()=>  import('@/views/HomeSelectWidgetMove.vue'),

    },
    meta: { authorize: ['ROLE_ADMIN','ROLE_USER'] } ,
  },
  {
    path: '/users',
    name: 'home-admin',
    components: {
      default:()=>import('@/views/users/ListUsers.vue'),
      LeftSidebar: () => import('@/views/layout/AsideBar.vue'),
      RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),
    },
    meta: { authorize: ['ROLE_ADMIN'] } ,
    children:[
      {
        path: 'create-user',
        name: 'create-user',
        component: ()=>import('@/views/users/CreateUser.vue'),
        meta: { authorize: ['ROLE_ADMIN'] } ,
      },
      {
        path: 'update-user/:id',
        name: 'update-user',
        component: ()=>import("@/views/users/UpdateUser.vue"),
        meta: { authorize: ['ROLE_ADMIN'] } ,
      },
    ]
  },
  {
    path: '/notices',
    name: 'notices',
    components: {
      default:()=>import("@/views/notice/ListNotice.vue"),
      LeftSidebar: () => import('@/views/layout/AsideBarAnimals.vue'),
      RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),
    },
    meta: { authorize: ['ROLE_ADMIN','ROLE_USER'] } ,
    children:[
      {
        path: 'create-notice',
        name: 'create-notice',
        component: ()=>import("@/views/notice/CreateNotice.vue"),
        meta: { authorize: ['ROLE_ADMIN','ROLE_USER'] } ,
      },
      {
        path: 'update-notice/:id',
        name: 'update-notice',
        component: ()=>import("@/views/notice/UpdatNotice.vue"),
        meta: { authorize: ['ROLE_ADMIN','ROLE_USER'] } ,
      },
    ]
  }, {
    path: '/profile',
    name: 'profile',
    components: {
      default: () => import("@/views/profile/ShowProfile.vue"),
      LeftSidebar: () => import('@/views/layout/AsideBarUser.vue'),
      RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),
    },
    meta: {authorize: ['ROLE_ADMIN', 'ROLE_USER']},
  },
  {
    path: '/change-password',
    name: 'change-password',
    components: {
      default: () => import("@/views/profile/ChangePassword.vue"),
      LeftSidebar: () => import('@/views/layout/AsideBarUser.vue'),
      RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),
    },
    meta: {authorize: ['ROLE_ADMIN', 'ROLE_USER']},
  },

  {
    path: '/clients',
    name: 'clients',
    components: {
      default:()=>import("@/views/clients/ListClients.vue"),
      LeftSidebar: () => import('@/views/layout/AsideBar.vue'),
      RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),
    },
    meta: { authorize: ['ROLE_ADMIN'] } ,
    children:[
      {
        path: 'create-client',
        name: 'create-client',
        component: ()=>import("@/views/clients/CreateClient.vue"),
        meta: { authorize: ['ROLE_ADMIN'] } ,
      },
      {
        path: 'update-client/:id',
        name: 'update-client',
        component: ()=>import("@/views/clients/UpdateClient.vue"),
        meta: { authorize: ['ROLE_ADMIN'] } ,
      },
    ]
  },
  {
    path: '/commercials',
    name: 'commercials',
    components: {
      default:()=>import("@/views/commercials/ListCommercial.vue"),
      LeftSidebar: () => import('@/views/layout/AsideBar.vue'),
      RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),
    },
    meta: { authorize: ['ROLE_ADMIN'] } ,
    children:[
      {
        path: 'create-commercial',
        name: 'create-commercial',
        component: ()=>import("@/views/commercials/CreateCommercial.vue"),
        meta: { authorize: ['ROLE_ADMIN'] } ,
      },
      {
        path: 'update-commercial/:id',
        name: 'update-commercial',
        component: () => import('@/views/commercials/UpdateCommercial.vue'),
        meta: { authorize: ['ROLE_ADMIN'] } ,
      }, 
    ]
  },
  {
    path: '/users',
    name: 'users-admin',
    components: {
      default:()=>import("@/views/users/ListUsers.vue"),
      LeftSidebar: () => import('@/views/layout/AsideBar.vue'),
      RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),
    },
    meta: { authorize: ['ROLE_ADMIN'] } ,
  },
  {
    path: '/main',
    name: 'home-tasks',
    components: {
      default:()=>import("@/views/tasks/listTasks.vue"),
      LeftSidebar: () => import('@/views/layout/AsideBar.vue'),
      RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),
    },
  },
  {
    path: '/home',
    name: 'home-user',
    components: {
      default:()=>import("@/views/HomeUserView.vue"),
      
    },
    meta: { authorize: ['ROLE_USER','ROLE_ADMIN'] } ,
  },
  {
    path: '/selectDomain',
    name: 'select-domain',
    components: {
      default:()=>import("@/views/HomeSelectFarms.vue"),
      
    },
    meta: { authorize: ['ROLE_USER','ROLE_ADMIN'] } ,
  },
  {
    
      path: '/selectWidget',
      name: 'select-widyet',
      components: {
        default:()=>import("@/views/HomeSelectWidget.vue"),
        
      },
      meta: { authorize: ['ROLE_USER'] } ,
    
  },
  {
    path: '/stables',
      name: 'stables',
      components: {
        default:()=>import("@/views/stables/ListStable.vue"),
        LeftSidebar: () => import('@/views/layout/AsideBarFarms.vue'),
        RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),
        
      },
      meta: { authorize: ['ROLE_USER',] } ,
      children:[
        {
          path: 'create-stable',
          name: 'create-stable',
          component: ()=>import("@/views/stables/createStable.vue"),
          meta: { authorize: ['ROLE_USER'] } ,
        },
        {
          path: 'update-stable/:id',
          name: 'update-stable',
          component: () => import('@/views/stables/updateStable.vue'),
          meta: { authorize: ['ROLE_USER'] } ,
        }, 
        {
          path: 'show-stable/:id',
          name: 'show-stable',
          component: () => import('@/views/stables/showStable.vue'),
          meta: { authorize: ['ROLE_USER'] } ,
        }, 
      ]
  },
  {
    path: '/warehouses',
      name: 'warehouses',
      components: {
        default:()=>import("@/views/warehouses/WareHouseList.vue"),
        LeftSidebar: () => import('@/views/layout/AsideBarFarms.vue'),
        RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),
        
      },
      meta: { authorize: ['ROLE_USER',] } ,
      children:[
        {
          path: 'create-warehouse',
          name: 'create-warehouse',
          component: ()=>import("@/views/warehouses/createWarehouses.vue"),
          meta: { authorize: ['ROLE_USER'] } ,
        },
        {
          path: 'update-warehouse/:id',
          name: 'update-warehouse',
          component: () => import('@/views/warehouses/updateWarehouse.vue'),
          meta: { authorize: ['ROLE_USER'] } ,
        }, 
        {
          path: 'show-warehouse/:id',
          name: 'show-warehouse',
          component: () => import('@/views/warehouses/showWarehouse.vue'),
          meta: { authorize: ['ROLE_USER'] } ,
        }, 
      ]
  },
  {
    path: '/locations',
      name: 'locations',
      components: {
        default:()=>import("@/views/locations/LocationsList.vue"),
        LeftSidebar: () => import('@/views/layout/AsideBarFarms.vue'),
        RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),
        
      },
      meta: { authorize: ['ROLE_USER',] } ,
      children:[
        {
          path: 'create-location',
          name: 'create-location',
          component: ()=>import("@/views/locations/createLocation.vue"),
          meta: { authorize: ['ROLE_USER'] } ,
        },
        {
          path: 'update-location/:id',
          name: 'update-location',
          component: () => import('@/views/locations/updateLocation.vue'),
          meta: { authorize: ['ROLE_USER'] } ,
        }, 
        {
          path: 'show-location/:id',
          name: 'show-location',
          component: () => import('@/views/locations/showLocation.vue'),
          meta: { authorize: ['ROLE_USER'] } ,
        }, 
      ]
  },
  {
    path: '/farms',
      name: 'farms',
      components: {
        default:()=>import("@/views/farms/ListFarms.vue"),
        LeftSidebar: () => import('@/views/layout/AsideBarFarms.vue'),
        RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),
        
      },
      meta: { authorize: ['ROLE_USER',] } ,
      children:[
        {
          path: 'create-farm',
          name: 'create-farm',
          component: ()=>import("@/views/farms/CreateFarm.vue"),
          meta: { authorize: ['ROLE_USER'] } ,
        },
        {
          path: 'update-farm/:id',
          name: 'update-farm',
          component: () => import('@/views/farms/UpdateFarm.vue'),
          meta: { authorize: ['ROLE_USER'] } ,
        }, 
        {
          path: 'show-farm/:id',
          name: 'show-farm',
          component: () => import('@/views/farms/ShowFarms.vue'),
          meta: { authorize: ['ROLE_USER'] } ,
        }, 
      ]
  },
  {
    path: '/feeds',
      name: 'feeds',
      components: {
        default:()=>import("@/views/feeds/ListFeeds.vue"),
        LeftSidebar: () => import('@/views/layout/AsideBarFarms.vue'),
        RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),
        
      },
      meta: { authorize: ['ROLE_USER'] } ,
      children:[
        {
          path: 'create-feeds',
          name: 'create-feeds',
          component: ()=>import("@/views/feeds/createFeed.vue"),
          meta: { authorize: ['ROLE_USER'] } ,
        },
        {
          path: 'update-feed/:id',
          name: 'update-feed',
          component: () => import('@/views/feeds/updateFeed.vue'),
          meta: { authorize: ['ROLE_USER'] } ,
        }, 
        {
          path: 'show-feed/:id',
          name: 'show-feed',
          component: () => import('@/views/feeds/ShowFeed.vue'),
          meta: { authorize: ['ROLE_USER'] } ,
        }, 
      ]
  },
  {
    path: '/feeds-in',
    name: 'feeds-in',
    components: {
      default:()=>import("@/views/feeds/ListFeeds.vue"),
      LeftSidebar: () => import('@/views/layout/AsideBarFeed.vue'),
      RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),
    },
    meta: { authorize: ['ROLE_ADMIN','ROLE_USER'] } ,
    children:[
      {
        path: 'create-feedsin',
        name: 'create-feedsin',
        component: ()=>import("@/views/feeds/createFeedIn.vue"),
        meta: { authorize: ['ROLE_ADMIN','ROLE_USER'] } ,
      },
      {
        path: 'update-feed/:id',
        name: 'update-feed',
        component: () => import('@/views/feeds/updateFeed.vue'),
        meta: { authorize: ['ROLE_ADMIN','ROLE_USER'] } ,
      },
      {
        path: 'show-feed/:id',
        name: 'show-feed',
        component: () => import('@/views/feeds/ShowFeed.vue'),
        meta: { authorize: ['ROLE_ADMIN','ROLE_USER'] } ,
      },
    ]
  },
  {
    path: '/tasks',
      name: 'tasks',
      components: {
        default:()=>import("@/views/tasks/listTasks.vue"),
        LeftSidebar: () => import('@/views/layout/AsideBarFarms.vue'),
        RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),
        
      },
      meta: { authorize: ['ROLE_USER'] } ,
  },
  {
    path: '/vaccines',
      name: 'vaccines',
      components: {
        default:()=>import("@/views/vaccines/ListVaccines.vue"),
        LeftSidebar: () => import('@/views/layout/AsideBarFarms.vue'),
        RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),
        
      },
      meta: { authorize: ['ROLE_USER'] } ,
      children:[
        {
          path: 'create-vaccine',
          name: 'create-vaccine',
          component: ()=>import("@/views/vaccines/createVaccine.vue"),
          meta: { authorize: ['ROLE_USER'] } ,
        },
        {
          path: 'update-vaccine/:id',
          name: 'update-vaccine',
          component: () => import('@/views/vaccines/updateVaccine.vue'),
          meta: { authorize: ['ROLE_USER'] } ,
        }, 
        {
          path: 'show-vaccine/:id',
          name: 'show-vaccine',
          component: () => import('@/views/vaccines/showVaccine.vue'),
          meta: { authorize: ['ROLE_USER'] } ,
        }, 
      ]
  },
  {
    path: '/suppliers',
      name: 'suppliers',
      components: {
        default:()=>import("@/views/suppliers/ListSuppliers.vue"),
        LeftSidebar: () => import('@/views/layout/AsideBarFarms.vue'),
        RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),
        
      },
      meta: { authorize: ['ROLE_USER'] } ,
      children:[
        {
          path: 'create-supplier',
          name: 'create-supplier',
          component: ()=>import("@/views/suppliers/createSupplier.vue"),
          meta: { authorize: ['ROLE_USER'] } ,
        },
        {
          path: 'update-supplier/:id',
          name: 'update-supplier',
          component: () => import('@/views/suppliers/updateSupplier.vue'),
          meta: { authorize: ['ROLE_USER'] } ,
        }, 
        {
          path: 'show-supplier/:id',
          name: 'show-supplier',
          component: () => import('@/views/suppliers/showSupplier.vue'),
          meta: { authorize: ['ROLE_USER'] } ,
        }, 
      ]
  },
  {
    path: '/destinations',
      name: 'destinations',
      components: {
        default:()=>import("@/views/destinations/ListDestinations.vue"),
        LeftSidebar: () => import('@/views/layout/AsideBarFarms.vue'),
        RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),
        
      },
      meta: { authorize: ['ROLE_USER'] } ,
    children:[
      {
        path: 'create-destination',
        name: 'create-destination',
        component: ()=>import("@/views/destinations/createDestination.vue"),
        meta: { authorize: ['ROLE_USER'] } ,
      },
      {
        path: 'update-destination/:id',
        name: 'update-destination',
        component: () => import('@/views/destinations/updateDestination.vue'),
        meta: { authorize: ['ROLE_USER'] } ,
      },
      {
        path: 'show-destination/:id',
        name: 'show-destination',
        component: () => import('@/views/destinations/showDestination.vue'),
        meta: { authorize: ['ROLE_USER'] } ,
      },
    ]

  },{
    path: '/groups',
    name: 'groups-animals',
    components: {
      default:()=>import("@/views/group/ListGroup.vue"),
      LeftSidebar: () => import('@/views/layout/AsideBarAnimals.vue'),
      RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),

    },
    meta: { authorize: ['ROLE_USER'] } ,
    children:[
      {
        path: 'create-group',
        name: 'create-group',
        component: ()=>import("@/views/group/createGroupAnimal.vue"),
        meta: { authorize: ['ROLE_USER'] } ,
      },
      {
        path: 'update-groups/:id',
        name: 'update-groups',
        component: () => import('@/views/group/editGroupAnimal.vue'),
        meta: { authorize: ['ROLE_USER'] } ,
      },
      {
        path: 'show-groups/:id',
        name: 'show-groups',
        component: () => import('@/views/group/showGroupAnimal.vue'),
        meta: { authorize: ['ROLE_USER'] } ,
      },
    ]

  },{
    path: '/prescriptionstoanimal',
    name: 'prescriptionstoanimal',
    components: {
      default:()=>import("@/views/prescription/createPrescriptionToAnimals.vue"),
      LeftSidebar: () => import('@/views/layout/AsideBarAnimals.vue'),
      RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),

    },
    meta: { authorize: ['ROLE_USER'] } ,


  },
  {
    path: '/vets',
      name: 'vets',
      components: {
        default:()=>import("@/views/vets/ListVets.vue"),
        LeftSidebar: () => import('@/views/layout/AsideBarFarms.vue'),
        RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),
        
      },
      meta: { authorize: ['ROLE_USER'] } ,
    children:[
      {
        path: 'create-veterinarian',
        name: 'create-veterinarian',
        component: ()=>import("@/views/vets/createVet.vue"),
        meta: { authorize: ['ROLE_USER'] } ,
      },
      {
        path: 'update-veterinarian/:id',
        name: 'update-veterinarian',
        component: () => import('@/views/vets/updateVet.vue'),
        meta: { authorize: ['ROLE_USER'] } ,
      },
      {
        path: 'show-veterinarian/:id',
        name: 'show-veterinarian',
        component: () => import('@/views/vets/showVet.vue'),
        meta: { authorize: ['ROLE_USER'] } ,
      },
    ]

  },{
    path: '/select-animals',
    name: 'select-animals',
    components: {
      default:()=>import("@/views/animal/ListAnimalsToSelect.vue"),
      LeftSidebar: () => import('@/views/layout/AsideBarAnimals.vue'),
      RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),

    },
    meta: { authorize: ['ROLE_USER'] },
  },
  {
    path: '/select-location',
    name: 'select-location',
    components: {
      default:()=>import("@/views/SelectTreeLocation.vue"),
      LeftSidebar: () => import('@/views/layout/AsideBarLocations.vue'),
      RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),

    },
    meta: { authorize: ['ROLE_USER'] },
  },
  {
    path: '/movestable',
    name: 'movestable',
    components: {
      default:()=>import("@/views/exits/moveStable.vue"),
      LeftSidebar: () => import('@/views/layout/AsideBarLocations.vue'),
      RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),

    },
    meta: { authorize: ['ROLE_USER'] },
  },
  {
    path: '/weigh',
    name: 'weigh',
    components: {
      default:()=>import("@/views/weighing/createWeigh.vue"),
      LeftSidebar: () => import('@/views/layout/AsideBarAnimals.vue'),
      RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),

    },
    meta: { authorize: ['ROLE_USER'] },
  },{
    path: '/vaccinetoanimals',
    name: 'vaccinetoanimals',
    components: {
      default:()=>import("@/views/vaccines/createVaccineToAnimal.vue"),
      LeftSidebar: () => import('@/views/layout/AsideBarAnimals.vue'),
      RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),

    },
    meta: { authorize: ['ROLE_USER'] },
  },
  {
    path: '/deseasetooanimals',
    name: 'deseasetooanimals',
    components: {
      default:()=>import("@/views/disease/createDiseaseToAnimals.vue"),
      LeftSidebar: () => import('@/views/layout/AsideBarAnimals.vue'),
      RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),

    },
    meta: { authorize: ['ROLE_USER'] },
  },
  {
    path: '/feedtoanimals',
    name: 'feedtoanimals',
    components: {
      default:()=>import("@/views/feeds/createFeedToAnimals.vue"),
      LeftSidebar: () => import('@/views/layout/AsideBarAnimals.vue'),
      RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),

    },
    meta: { authorize: ['ROLE_USER'] },
  },
  {
    path: '/moveanimals',
    name: 'moveanimals',
    components: {
      default:()=>import("@/views/stables/createMoveAnimals.vue"),
      LeftSidebar: () => import('@/views/layout/AsideBarAnimals.vue'),
      RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),

    },
    meta: { authorize: ['ROLE_USER'] },
  },
  {
    path: '/animals',
    name: 'animals',
    components: {
      default:()=>import("@/views/animal/ListAnimals.vue"),
      LeftSidebar: () => import('@/views/layout/AsideBarAnimals.vue'),
      RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),

    },
    meta: { authorize: ['ROLE_USER'] } ,
    children:[
      {
        path: 'create-animal',
        name: 'create-animal',
        component: ()=>import("@/views/animal/CreateAnimal.vue"),
        meta: { authorize: ['ROLE_USER'] } ,
      },
      {
        path: 'create-group-animal',
        name: 'create-group-animal',
        component: ()=>import("@/views/animal/CreateGroupAnimal.vue"),
        meta: { authorize: ['ROLE_USER'] } ,
      },
      {
        path: 'import-group-animal',
        name: 'import-group-animal',
        component: ()=>import("@/views/animal/ImportGroupAnimal.vue"),
        meta: { authorize: ['ROLE_USER'] } ,
      },
      {
        path: 'update-animal/:id',
        name: 'update-animal',
        component: () => import('@/views/animal/UpdateAnimal.vue'),
        meta: { authorize: ['ROLE_USER'] } ,
      },
      {
        path: 'show-animal/:id',
        name: 'show-animal',
        component: () => import('@/views/animal/ShowAnimal.vue'),
        meta: { authorize: ['ROLE_USER'] } ,
      },
    ]

  },
  {
    path: '/informs',
    name: 'informs',
    components: {
      default:()=>import("@/views/informes/InformesView.vue"),
      LeftSidebar: () => import('@/views/layout/AsideBarInforms.vue'),
      RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),

    },
    meta: { authorize: ['ROLE_USER'] } ,
  },{
    path: '/graphic',
    name: 'graphic',
    components: {
      default:()=>import("@/views/informes/InformConventionChart.vue"),
      LeftSidebar: () => import('@/views/layout/AsideBarInforms.vue'),
      RightSidebar: () => import('@/views/layout/AsideBarRigth.vue'),

    },
    meta: { authorize: ['ROLE_USER'] } ,
  }


];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  });

  const canUserAccess = async to=>{
      //   console.log("TO",to);
      const user = JSON.parse(localStorage.getItem('beefyUser'));
      
      if(!user)return false;
      if(!to || !to.meta || !to.meta.authorize || to.meta.authorize.length===0)return false;
    
      //console.log("USER",user);
      return to.meta.authorize.some(v=>user.roles.includes(v));
      
  }
  const isAdmin = ()=>{
    const user = JSON.parse(localStorage.getItem('beefyUser'));
    return user.roles.includes("ROLE_ADMIN");
  }
  const isUser = ()=>{
    const user = JSON.parse(localStorage.getItem('beefyUser'));
    return user.roles.includes("ROLE_USER");
  }

  //const canAccess = await canUserAccess(to)
  // ...
  
  // This callback runs before every route change, including on page load.
  router.beforeEach(async(to, from, next) => {
   
    if(to.name==='Loggin')return next();
    if(to.name==='forgotpassword')return next();
    if(to.name==='resetPassword')return next();
    if(!localStorage.getItem('tokenAuths') && to.path !== '/loggin') {
     console.log("no aute"); 
     return next({name:'Loggin'});
    
   }
   if(to.name==='NotHavePermission')return next();
  

   if(to.path ==='/' && localStorage.getItem('tokenAuths')){
      if(isAdmin())
        return next({name:'home-admin'});
      if(isUser())
        return next({name:'home-user'});
      

    }
        
  const canAccess = await canUserAccess(to);
  if(!canAccess){
   return next({name:'NotHavePermission'});
 } 

    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  
    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  
    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  
    // If a route with a title was found, set the document (page) title to that value.
    if(nearestWithTitle) {
      document.title = nearestWithTitle.meta.title;
      next()
    } else if(previousNearestWithMeta) {
      document.title = previousNearestWithMeta.meta.title;
    }
  
    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  
    // Skip rendering meta tags if there are none.
    if(!nearestWithMeta) return next();
  
    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta');
  
      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });
  
      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');
  
      return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));
  
     
  
    
    return next();
  });
  
  
  // ...
  export default router;